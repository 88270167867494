import React from 'react'
import LocationsTiles from '../locations_tiles/locations_tiles'
import Figma from '../figma/figma'
import styles from './our_offices.module.css'
import translations from './our_offices.translations'
import { useTranslation } from '../../i18n'
import CenteredSectionWrapper from '../centered_section_wrapper/centered_section_wrapper'

const OurOffices = () => {
  const t = useTranslation(translations)

  return (
    <section className={styles.root}>
      <CenteredSectionWrapper>
        <Figma.Text block type='mega'>
          {t.title}
        </Figma.Text>
        <LocationsTiles />

        <div className={styles.horizontalLine} />
      </CenteredSectionWrapper>
    </section>
  )
}
export default OurOffices
